<template>
  <div class="recharge-container">
    <h2>积分充值</h2>
    <div class="recharge-options">
      <div
        class="option"
        v-for="option in options"
        :key="option.value"
        :class="{ selected: selectedOption === option.value }"
        @click="selectOption(option.value)"
      >
        <span class="price">{{ option.value }}元</span>
        <span class="points">{{ option.points }}积分</span>
      </div>
    </div>

    <div class="selected-option" v-if="selectedOption">
      <h3>已选充值:</h3>
      <div class="pay-module">
        <span>购买份数:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="1"
          :max="100"
          label="购买份数"
          class="buy-num"
        ></el-input-number>
      </div>
      <div class="buy-goods">
        <span>购买商品名称: </span>
        <div class="buy-content">
          <span>{{ totalPrice }}元</span>
          <span>{{ total_integral }}积分</span>
        </div>
      </div>
    </div>
    <div class="total-price-container">
      总支付金额: <span class="total-price">{{ totalPrice }}</span
      >元
    </div>
    <button class="pay-button" @click="pay">立即支付</button>
  </div>
</template>

<script>
import { get_token } from "../common/common.js";

export default {
  data() {
    return {
      options: [
        { value: 1, points: 10 },
        { value: 10, points: 130 },
        { value: 30, points: 450 },
        { value: 50, points: 1000 },
      ],
      selectedOption: null,
      selectedPoints: 0,
      total_integral: 0,
      num: 1,
    };
  },
  computed: {
    totalPrice() {
      return this.selectedOption * this.num || 0;
    },
  },
  methods: {
    selectOption(value) {
      this.num = 1;
      this.selectedOption = value;
      const selected = this.options.find((option) => option.value === value);
      this.selectedPoints = selected.points;
      this.total_integral = selected.points;
    },

    handleChange() {
      this.total_integral = this.selectedPoints * this.num;
      // console.log(
      //   "this.num",
      //   this.num,
      //   this.selectedPoints,
      //   this.total_integral
      // );
    },

    pay() {
      if (this.selectedOption) {
        // console.log("付款的前:", this.num, this.num * this.selectedOption);
        alert(`支付${this.totalPrice}元，获得${this.total_integral}积分`);
        this.submit_pay();
        // 在这里添加支付逻辑
      } else {
        alert("请选择一个充值选项。");
      }
    },

    async submit_pay() {
      try {
        const token = get_token("token");
        const data = {
          name: "积分充值",
          num: this.num,
          price: this.selectedOption,
          integral: this.total_integral,
          type: "订单",
        };

        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/send_pay_request", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/send_pay_request",
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${token}`,
        //     },
        //     data,
        //   }
        // );

        // console.log("获取赞助数据：2 ", response.data);

        if (response.data.status == 200) {
          const url = response.data.data.url;
          // console.log("塑料化工公司的url: ", response.data);
          // this.$router.push(url);
          window.open(url);
        } else {
          this.$message({
            message: "跳转支付失败，请检查后在进行支付",
            type: "error",
          });
        }

        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },
  },
};
</script>

<style scoped>
.recharge-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
}

.buy-num {
  width: 150px;
}
.recharge-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
}

.option.selected {
  background-color: #d32f2f;
  color: #fff;
}

.price,
.points {
  font-size: 18px;
}

.selected-option {
  margin-bottom: 20px;
  text-align: center;
}

.total-price-container {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.total-price {
  font-weight: bold;
}

.pay-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.pay-button:hover {
  background-color: #c2185b;
}

.buy-goods {
  display: flex;
  justify-content: space-between;
}

.pay-module {
  display: flex;
  justify-content: space-between;
}
</style>
